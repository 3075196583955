.calender_body {
  padding: 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.calender_block {
  max-width: inherit !important;
  width: 30.3% !important;
  margin: 10px 0.5% !important;
  background: #f2f8fa;
  padding: 0.5% !important;
  border: solid 1px #f5e4c7 !important;
  word-break: break-all;
  transition: all 0.2s ease-in-out;
  box-shadow: 0px 2px 1px white inset, 0px -2px 8px white, 0px 2px 5px rgb(0 0 0 / 10%), 0px 8px 10px rgb(0 0 0 / 10%);
  position: relative;
}

.calender_block:hover {
  transform: scale(1.1);
}

.calender_block h2 {
  margin: 0;
  display: block;
  background: #00abff;
  background: linear-gradient(90deg, #00abff 0%, #fc00ff 100%);

  font-size: 18px;
  padding: 8px 5px;
  font-size: 18px;
  text-transform: capitalize;
  color: #fff;
  margin-bottom: 6px;
}

.calender_block p {
  margin: 0;
  padding: 6px 0;
}

.calender_block p span {
  color: #3f86ce;
}

.formCls .multipleCheckbox .MuiFormControl-root {
  flex: 1 0 100%;
  box-shadow: none;
}
.formCls .eventDetails .MuiFormLabel-root {
  position: relative;
  height: 50px;
  width: auto;
  background: #626fb1;
  text-align: left;
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 16px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  transform: none;
  text-transform: capitalize;
}
.formCls .multipleCheckbox .MuiFormControlLabel-label {
  color: #000;
}
.formCls .multipleCheckbox .css-9npbnl-MuiFormLabel-root-MuiInputLabel-root {
  color: rgb(0 0 0);
  font-weight: 600;
}
.formCls .singleCheckbox .MuiFormControl-root {
  color: #000;
  flex: 1 0 100%;
  box-shadow: none;
}
.formCls .singleCheckbox .MuiInputLabel-root {
  color: #000;
  font-weight: 600;
}
.formCls .singleCheckbox {
  padding: 0 8px !important;
}

.ContentWrapper {
  width: 1780px;
  margin: 20px auto 0;
  display: block;
}

.connectedemail {
  margin: 0;
  background: #00abff;
  background: linear-gradient(90deg, #00abff 0%, #fc00ff 100%);
  font-size: 18px;
  padding: 18px;
  text-transform: capitalize;
  color: #fff;
  margin-bottom: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 15px;
}
.connectedemail h2{margin: 0;margin-bottom: 15px;}

.calenderinfo {
  width: 450px;
}
.calenderinfo p {
  margin: 0;
  color: #fff;
  font-weight: 500;
  font-size: 20px;
}

.buttonsetToolbar {
    margin: 0;
    background: #c9d2d9;
    font-size: 18px;
    padding: 18px;
    text-transform: capitalize;
    color: #fff;
    margin-bottom: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    margin-top: 20px;
}
.buttonsetToolbar button{margin:5px}
.buttonsetToolbar button a, .buttonsetToolbar a{color:#fff;text-decoration:none;    font-size: 18px;}
.buttonsetToolbar button:hover a, .buttonsetToolbar a:hover{color:#000;}
.formCls {
  margin-top: 20px;
}

.reactTable .calendarAvailabilityTable{width:100%;}

@media screen and (max-width: 1800px) {
.ContentWrapper {
  width: 96%;
}
}

@media screen and (max-width: 1199px) {
  .calender_block {
    width: 49% !important;
  }
}

@media screen and (max-width: 767px) {
  .calender_block {width: 99% !important; }
  .connectedemail {flex-wrap: wrap;}
}
@media screen and (max-width: 540px) {
  .buttonsetToolbar {flex-wrap: wrap;}
}
